import { createSlice } from '@reduxjs/toolkit';

const initMonth = {}; 

const initialState = {
  selectedMonth: initMonth, 
  selectedIndex: 0 //Do we really need an index to make this work ?
};

const CalendarSlice = createSlice({
  name: 'selectedmonth',
  initialState,
  reducers: {
    setSelectedMonth: (state, action) => {
      state.selectedMonth = action.payload.month;
      state.selectedIndex = action.payload.index;
    }
  }
});

export const { setSelectedMonth } = CalendarSlice.actions;
export default CalendarSlice.reducer;
