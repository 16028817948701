import React from "react";
import {Card } from "react-bootstrap";
import {Link} from "react-router-dom";

import EventTile from "./EventTile";

function CalendarDay({theDate, theUser}) {

    return (
        
        <Card className="text-center my-2 date-card">

            <Card.Body className="d-flex flex-column">

                <Card.Title><Link to = "/calendarfullday" state = {{selectDate: theDate}} style={{ textDecoration: 'none', color: 'inherit' }}>{theDate.event_date_id.toString().slice(-2)}</Link></Card.Title>
                <Card.Text>{theDate.event_date_day}</Card.Text>

                {
                    theDate.events.map((event, index) => 
                        <EventTile key = {event.event_id} theEvent = {event} theUser = {theUser}/>
                    )
                }

            </Card.Body>

        </Card>

    )
    
}

export default CalendarDay;