import { createSlice } from '@reduxjs/toolkit';

const initUser = {}

const initialState = {
  loggedInUser: initUser
};

const UserSlice = createSlice({
  name: 'loggeduser',
  initialState,
  reducers: {
    setLoggedUser: (state, action) => {
      state.loggedInUser = action.payload.user;
    }
  }
});

export const { setLoggedUser } = UserSlice.actions;
export default UserSlice.reducer;
