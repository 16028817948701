import React, {useState, useEffect} from "react";
import { useNavigate } from 'react-router-dom';

import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import { useDispatch } from 'react-redux';
import { setLoggedUser } from '../redux/UserSlice';
import { CognitoUserPool, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';

import axios from "axios";

function HandleLogin({loginMessage}) {

    const navigate = useNavigate(); //Required to route back to calendar page once Event has been handled
    const dispatch = useDispatch();

    const [theUser, settheUser] = useState("");
    const [thePassword, setthePassword] = useState("");
    const [theMessage, settheMessage] = useState("");
    const [isSubmit, setisSubmit] = useState(false);

    //My AWS Cognito Pool (Pool and App Client ID)
    //ACTION: Should probably move these into AWS Secrets
    const poolData = {
        UserPoolId: 'ap-southeast-2_g8w1Nq54l', 
        ClientId: '5j9650d9hn8kg8ih0gtdfjic58' 
      };
      
    const userPool = new CognitoUserPool(poolData);

    //Explcitly reset the logged in user. This is there for Routes back to the Login Page following token expiry
    useEffect(() => {
        settheMessage(loginMessage);
        dispatch(setLoggedUser({user:{}}));
    }, []);

    //Handling the Form Submit
    const handleSubmit = async (e) => {

        e.preventDefault();

        //To ensure no additional events occur during async calls
        setisSubmit(true);

        //Authentication with Cognito
        const userData = {
            Username: theUser,
            Pool: userPool,
        };
      
        const cognitoUser = new CognitoUser(userData);

        const authDetails = new AuthenticationDetails({
            Username: theUser,
            Password: thePassword,
          });

        cognitoUser.authenticateUser(authDetails, {

            onSuccess: (result) => {
                
                const accessToken = result.getAccessToken().getJwtToken();
                const idToken = result.getIdToken().getJwtToken();

                //Get the Users additional information via API
                const fetchUser = async () => {

                    try {

                        const response = await axios.get(`https://api.leost.co.nz/user/${theUser}`, {
                            headers: {
                                Authorization: `Bearer ${accessToken}`
                            }
                        });
                        
                        const userID = response.data[0].event_user_id;
                        const userType = response.data[0].event_user_type;
                        const userBadge = response.data[0].event_user_badge;

                        //Construct the User Object (including Tokens)
                        const userObject = {userID: userID, userLogin: theUser, userType: userType, userBadge: userBadge, accessJWT: accessToken, idJWT: idToken};

                        dispatch(setLoggedUser({user:userObject}));

                        navigate("/calendar"); // Navigate to calendar page
            
                    } catch (error) {
                        console.error('Error fetching Users data:', error);
                        settheMessage("Login failed. Please try again.");
                        setisSubmit(false);
                    }
                }

                fetchUser();

            },
            onFailure: (err) => {
                console.error('Error during authentication:', err);
                settheMessage("Login failed. Please try again.");
                setisSubmit(false);
            }
        });

    };

    //Clear the Event Entry
    const handleClear = (e) => {
        settheUser("");
        setthePassword("");
        settheMessage("");
    };

    return (

        <Container>

            <Row className="justify-content-md-center">
                <Col md={12} sm={12} xs={12} className="mt-4 mb-4">
                    <h3>User Login</h3>
                </Col>
            </Row>

            { theMessage !== "" && (
            <Row>
                <Col md={12} sm={12} xs={12} className="mt-1 mb-1">
                    <p className="text-danger">{theMessage}</p>
                </Col>
            </Row>
            )}

            <Form onSubmit={handleSubmit}>

                <Row>
                    <Col md={4}>
                        <Form.Group controlId="userEmail" className="mb-3">
                            <Form.Label>User Email</Form.Label>
                            <Form.Control
                            type="text"
                            placeholder="Email Address"
                            value={theUser}
                            onChange={(e) => settheUser(e.target.value)}
                            maxLength={50}
                            required
                            />
                        </Form.Group>
                    </Col>
                </Row>
    
                <Row>
                    <Col md={4}>
                        <Form.Group controlId="userPassword" className="mb-3">
                            <Form.Label>User Password</Form.Label>
                            <Form.Control
                            type="password"
                            placeholder="Password"
                            value={thePassword}
                            onChange={(e) => setthePassword(e.target.value)}
                            maxLength={50}
                            required
                            autoComplete="new-password"
                            />
                        </Form.Group>
                    </Col>
                </Row>
            
                <Row className="mt-4">

                    <Col md={1} sm={2} xs={3}>
                        <Button variant="primary" type="submit" disabled={isSubmit}>
                        {isSubmit ? "..." : "Login"}
                        </Button>
                    </Col>

                    <Col md={1} sm={2} xs={3}>
                        <Button variant="secondary" type="button" onClick={handleClear} disabled={isSubmit}>
                        {isSubmit ? "..." : "Clear"}
                        </Button>
                    </Col>

                </Row>

            </Form>

        </Container>

    );

};

export default HandleLogin;