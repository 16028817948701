import React, {useState, useEffect} from "react";
import axios from "axios";

import { Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";

import { useSelector, useDispatch } from 'react-redux';
import { setSelectedMonth } from '../redux/CalendarSlice';

import CalendarDay from "./CalendarDay";

function CalendarGrid({theUser, theMonths}) {

    const dispatch = useDispatch();

    //Redux State for the selected month
    const {selectedMonth, selectedIndex} = useSelector((state) => state.selectedmonth);
    
    const [currDates, setCurrDates] = useState([]); //The Dates (with Events) for the currently selected month

    useEffect(() => {

    const fetchDates = async () => {
        try {
            const response = await axios.get(`https://api.leost.co.nz/month/${selectedMonth.event_month_id}`, {
                headers: {
                    Authorization: `Bearer ${theUser.accessJWT}`
                }
            });
            
            setCurrDates(response.data); 

        } catch (error) {
            setCurrDates([]);
            console.error('Error fetching Dates for selected Month:', error);
        }
    };

    fetchDates();

    }, [selectedMonth]);


    //Change the month that we are looking at
    function changeMonth(theDirection) {

        const maxMonth = theMonths.length - 1; 

        //Handle the changing of the month, either up or down
        if(theDirection === "1" && maxMonth >= selectedIndex + 1) {  
            dispatch(setSelectedMonth({ month: theMonths[selectedIndex + 1], index: selectedIndex + 1 }));
        }
        else if (theDirection === "0" && selectedIndex !== 0) {
            dispatch(setSelectedMonth({ month: theMonths[selectedIndex - 1], index: selectedIndex - 1 }));
        };

    }

    return (

        <Container fluid>

            <Row className="align-items-center text-center mt-3 mb-4">
                <Col md = {1} sm = {2} xs = {4}></Col>
                <Col md = {10} sm = {8} xs = {4}>
                    <h4>{selectedMonth.event_month_name}, {selectedMonth.event_month_year}</h4>
                </Col>
                <Col md = {1} sm = {2} xs = {4}>
                    <Link to = "/handlenewevent" style={{ textDecoration: 'none', color: 'inherit' }}>
                        <img className="img-plus" src="./plus.jpg" alt="Add Event" />
                    </Link>
                </Col>
            </Row>

            <Row>
                <Col md={1} sm = {2} xs = {2} className="d-flex justify-content-end"><img className="img-arrow" src="./triangle-left.png" alt="Previous month" onClick={() => changeMonth("0")} /></Col>
                <Col md={10} sm = {8} xs = {8} className="d-flex justify-content-center"></Col>
                <Col md={1} sm = {2} xs = {2} className="d-flex justify-content-start"><img className="img-arrow" src="./triangle-right.png" alt="Next month" onClick={() => changeMonth("1")} /></Col>
            </Row>
        
            <Row className="align-items-center">

                <Col md={1} sm = {2} xs = {2}></Col>
                <Col md={10} sm = {8} xs = {8}>

                    <Row>

                        {
                            currDates.map((date, index) => (
                                <Col md={2} sm={4} xs={6} key = {`${date.dateID}-${index}`}>
                                    <CalendarDay theDate = {date} theUser = {theUser}/>
                                </Col>
                            ))
                        }

                    </Row>

                </Col>

                <Col md={1} sm = {2} xs = {2}></Col>
        
            </Row>

        </Container>
          
    );

};

export default CalendarGrid;