import React from "react";

function About() {

    return (
        <div>
            <h3>About App</h3>
            <p>Created to manage the use of Mum + Dad Campbell's Leo St Beach House</p>
        </div>
    );

}

export default About;