import React from "react";
import {Container, Row, Col, Card } from "react-bootstrap";

import { useSelector } from 'react-redux';
import {Link} from "react-router-dom";

import EventTile from "./EventTile";

function CalendarFullDay({theUser, theDate}) {

    const {selectedMonth} = useSelector((state) => state.selectedmonth); //Get the current month for display purposes

    return (

    <Container fluid>

        <Row className="align-items-center mt-3 mb-4">

            <Col md = {1} sm = {2} xs = {4}></Col>

            <Col md={10} sm = {8} xs = {4} className="text-center mt-4">
                <h4>{selectedMonth.event_month_name}, {selectedMonth.event_month_year}</h4>
            </Col>

            <Col md = {1} sm = {2} xs = {4}>
                <Link to = "/handlenewevent" state = {{selectDate: theDate}} style={{ textDecoration: 'none', color: 'inherit' }}>
                    <img className="img-plus" src="./plus.jpg" alt="Add Event" />
                </Link>
            </Col>
        
        </Row>

        <Row className="align-items-center">

            <Col md = {2} sm = {2} xs = {2}></Col>

            <Col md={8} sm = {8} xs = {8}>
        
                <Card className="text-center my-2 h-100">

                    <Card.Body>

                        <Card.Title>{theDate.event_date_id.toString().slice(-2)}</Card.Title>
                        <Card.Text>{theDate.event_date_day}</Card.Text>

                        {
                            theDate.events.map((event, index) => 
                                <EventTile key = {index} theEvent = {event} theUser = {theUser}/>
                            )
                        }

                    </Card.Body>

                </Card>

            </Col>

            <Col md = {2} sm = {2} xs = {2}></Col>

        </Row>

    </Container>

    )
    
}

export default CalendarFullDay;