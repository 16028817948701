import React, {useEffect, useState} from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';

import axios from "axios";
import { jwtDecode } from "jwt-decode";

import { setSelectedMonth } from './redux/CalendarSlice';

import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is imported
import "./App.css"

import AppNavbar from './components/AppNavbar';
import CalendarGrid from  './components/CalendarGrid';
import About from "./components/About";
import CalendarFullDay from "./components/CalendarFullDay";
import HandleEvent from "./components/HandleEvent";
import HandleLogin from "./components/HandleLogin";

//Private Route to ensure authentication before Routing to other parts of the App
function PrivateRoute({ children }) {

  const { loggedInUser } = useSelector((state) => state.loggeduser);

  let isLoggedUser = Object.keys(loggedInUser).length !== 0;

  if (isLoggedUser) {

          const { exp } = jwtDecode(loggedInUser.accessJWT);
          const timeNow = Math.floor(Date.now() / 1000); //Can add number close to 1800 to simulate expiration

          if (exp < timeNow) {
            isLoggedUser = false;
          }

  };
  
  //Control the Route that is returned, based on the authentication checks above
  return isLoggedUser ? (
    children
  ) : (
    <Navigate to="/" state={{loginMessage: "Session Expired. Please Login."}}/>
  );

};

function App() {

  const dispatch = useDispatch();

  const {loggedInUser} = useSelector((state) => state.loggeduser);
  const [months, setMonths] = useState([]); //All of the selectable months

  //Get the payload that is passed into the Link. Undefined if the Routes don't have these
  const location = useLocation();
  const {selectDate} = location.state || {};
  const {selectEvent} = location.state || {};
  const {loginMessage} = location.state || "";

  //console.log(loggedInUser);

  //Setting up the selectable Months + the Current Month
  useEffect(() => {

    const fetchMonths = async () => {
        try {
            const response = await axios.get('https://api.leost.co.nz/month', {
              headers: {
                  Authorization: `Bearer ${loggedInUser.accessJWT}`
              }
          });
            
            //ACTION: Change to use redux instead of Local State. Works for now.
            setMonths(response.data); 
        
            //Find the month that has the 0 Index so that we can pass this to the initial status
            const findCurrent = response.data.findIndex((month) => month.event_month_index  === 0);
            
            dispatch(setSelectedMonth({month: response.data[findCurrent], index: findCurrent}));

        } catch (error) {
            setMonths([]);
            console.error('Error fetching Month data:', error);
        }
    };

    //Only make the API Call if the user login has been completed
    if(Object.keys(loggedInUser).length !== 0) {
      fetchMonths();
    };

  }, [loggedInUser]);

  return (

      <div>

        <AppNavbar theUser = {loggedInUser}/>

        <Routes>
          
          <Route path="/" element={<HandleLogin loginMessage = {loginMessage}/>}/>

          <Route path="/calendar" element={<PrivateRoute><CalendarGrid theUser = {loggedInUser} theMonths = {months}/></PrivateRoute>}/>
          <Route path="/about" element={<PrivateRoute><About theUser = {loggedInUser}/></PrivateRoute>}/>
          <Route path="/calendarfullday" element={<PrivateRoute><CalendarFullDay theUser = {loggedInUser} theDate = {selectDate}/></PrivateRoute>}/>
          <Route path="/handleevent" element={<PrivateRoute><HandleEvent theUser = {loggedInUser} theEvent = {selectEvent}/></PrivateRoute>}/>
          <Route path="/handlenewevent" element={<PrivateRoute><HandleEvent theUser = {loggedInUser} theEvent = {{}} theDate = {selectDate}/></PrivateRoute>}/>
        
        </Routes>

      </div>

  );

}

export default App;
