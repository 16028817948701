import React from "react";

import {Container, Nav, Navbar, Badge, Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";

function AppNavbar({theUser}) {

  //Basic check to see if there is a logged in user. Can use to conditional render NavBar
  const setUser = Object.keys(theUser).length === 0 ? "" : "Y";

    return (
        
      <Navbar sticky="top" expand = "xs" className="bg-body-tertiary" >

            <Container fluid className="px-5 align-items-center">

              <Row className="w-100">
                
                <Col md = {2} sm={2} xs={2} className="text-start">

                  {setUser && (

                    <>

                      <Navbar.Toggle aria-controls="basic-navbar-nav" />
                      <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="mx-auto">
                            <Nav.Link as={Link} to="/about" className="mx-3">About</Nav.Link>
                        </Nav>

                        <Nav className="mx-auto">
                            <Nav.Link as={Link} to="/" state={{loginMessage: "Successfully Logged Out."}} className="mx-3">Logout</Nav.Link>
                        </Nav>

                      </Navbar.Collapse>

                    </>

                  )}

                </Col>

                <Col md={8} sm={8} xs={8} className="text-center">

                  {setUser ? (
                    <Navbar.Brand>
                    <Nav.Link as={Link} to="/calendar" className="mx-3"><img src="../leostlogo.png" alt="Leo St" className="img-logo"/></Nav.Link>
                    </Navbar.Brand>
                  ) : (
                    <img src="../leostlogo.png" alt="Leo St" className="img-logo"/>
                  )}

                </Col>

                <Col md={2} sm={2} xs={2} className="text-end">
                  <Badge pill bg="success">{theUser.userBadge}</Badge>
                </Col>

              </Row>

            </Container>

          </Navbar>

    )

};

export default AppNavbar;