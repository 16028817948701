import { configureStore } from '@reduxjs/toolkit';
import calendarReducer from './CalendarSlice';
import userReducer from './UserSlice';

const store = configureStore({
  reducer: {
    selectedmonth: calendarReducer,
    loggeduser: userReducer
  }
});

export default store;
